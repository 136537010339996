import React, { useEffect, useState } from "react";
import TableOfContents from "../../molecules/TableOfContents";
import ContentSection from "../../molecules/ContentSection";
import { caseStudies } from "../../../Content/Common";

const Content = ({ slug }) => {
  const [selectedPost, setSelectedPost] = useState(null);

  useEffect(() => {
    const post = caseStudies.find(post => post.slug === slug);
    if (post) {
      setSelectedPost(post);
    }
  }, [slug]);

  if (!selectedPost) {
    return <div className="synkli-section--horizontal-space ">No Post found with such slug!</div>; // or handle this appropriately
  }

  const tocItems = selectedPost.caseStudyContent
    .map(section => {
      if (section.title) {
        return section.title
      }
      return null // Skip this item
    })
    .filter(Boolean)

  return (
    <div className="synkli-section--horizontal-space flex flex-col-reverse lg:flex-row gap-10">
      <div className="w-full lg:w-[70%]">
        {selectedPost.caseStudyContent.map((section, index) => (
          <ContentSection
            key={`section_` + index}
            title={section.title}
            titleEC={section.titleEC}
            description={section.description}
            list={section.list}
            image={section.image}
            imageEC={section.imageEC}
          />
        ))}
      </div>
      <div className="w-full lg:w-[30%]">
        <TableOfContents items={tocItems} />
      </div>
    </div>
  );
};

export default Content;

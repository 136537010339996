import React from "react"
import PageBgSectionHeader from "../../common/PageBgSectionHeader"
import InlineFormWithEmailField from "../../molecules/InlineFormWithEmailField"
import ImageRenderer from "../../atoms/ImageRenderer"

const BusinessModelsWithFormSection = () => {
  return (
    <div className="synkli-section--horizontal-space">
      <div className="mt-20 md:mt-24 mb-10 px-8 sm:px-16 flex flex-col gap-10 md:flex-row justify-between items-center sm:items-stretch bg-[#0A1E46] rounded-[30px]">
        <div className="lg:min-w-[500px] w-[100%] py-10 flex flex-col justify-center">
          <PageBgSectionHeader
            title={
              "Subscribe to newsletter to learn more about client management"
            }
            titleEC={"text-white"}
          />
          <InlineFormWithEmailField buttonText={"Subscribe"} />
        </div>
        <div className="max-w-[400px] md:max-w-[250px] lg:max-w-[600px] mt-3 flex justify-end items-center sm:items-end">
          <ImageRenderer img="business-models-mobiles" alt="Image" />
        </div>
      </div>
    </div>
  )
}

export default BusinessModelsWithFormSection

import React from "react"
import BusinessModelsWithFormSection from "../BlogDetails/BusinessModelsWithFormSection"
import CaseStudyHeader from "./CaseStudyHeader"
import { caseStudies } from "../../../Content/Common"
import Content from "./Content"

const CaseStudyContentBody = ({ slug }) => {

  const casestudy = caseStudies.find(post => post.slug === slug) // Find the blog post by slug

  if (!casestudy) {
    return (
        <div className="synkli-section--horizontal-space synkli-section--vertical-space">
          Case Study not found.
        </div>
    )
  }

  return (
    <>
      <CaseStudyHeader caseStudy={casestudy} />

      <div className="synkli-section--horizontal-space mt-5 md:mt-10 mb-10 md:mb-20">
        <hr />
      </div>

      <Content slug={slug} />


      <BusinessModelsWithFormSection
        title={"Synkli – Your Ultimate Business Management Partner"}
        description={`Elevate your business with Synkli, your comprehensive business management solution. Streamline operations, enhance efficiency, and drive growth with our intuitive software.`}
      />
    </>
  )
}

export default CaseStudyContentBody

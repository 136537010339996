import React from 'react'
import Button from '../atoms/Button'

const InlineFormWithEmailField = ({buttonText, buttonEC}) => {
  return (
    <div className='flex flex-col lg:flex-row justify-center items-center gap-3'>
      <input type='email' placeholder='Enter your email' className='border border-white font-[200] w-[100%] h-[56px] outline-none placeholder-white bg-transparent px-4 rounded-[16px] text-white'></input>
      <Button 
        to=""
        href="#"
        text={buttonText ? buttonText : "Submit"}
        ec={`secondary-btn !mt-0 text-[16px] lg:min-w-[190px] ${buttonEC}`}
      />
    </div>
  )
}

export default InlineFormWithEmailField

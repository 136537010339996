import React from "react"
import Button from "../atoms/Button"

const PageBgSectionHeader = ({
    title,
    titleEC,
    description,
    descriptionEC,
    button,
    buttonEC,
  }) => {
  return (
    <>
    {title ? (
      <h3
        className={
          "text-[22px] md:text-[24px] lg:text-[38px] mb-5 font-[600] leading-[1.5em] " +
            titleEC || ""
        }
      >
        {title}
      </h3>
    ) : (
      ""
    )}
    {description ? (
      <p
        className={
          "text-[16px] font-[200] mb-6 max-w-[500px] " +
          descriptionEC || ""
        }
      >
        {description}
      </p>
    ) : (
      ""
    )}
    {button && (
        <Button
          to={button?.to || "/"}
          href={button?.href || ""}
          text={button?.text || ""}
          ec={button?.ec + " " || ""}
          />
      )}
  </>
  )
}

export default PageBgSectionHeader

// Case Study Section
export const caseStudies = [
  {
    title: `Streamlining <span class="text-[#B695F8]">Financial Management</span> for Small Retail Business`,
    slug: `streamlining-financial-management-for-small-retail-business`,
    description: `GreenLeaf Boutique, a small retail business, struggled with managing its finances due to a lack of centralised financial tracking and inefficient manual processes. This led to frequent errors, delayed tax filings, and difficulty managing expenses.`,
    client: `GreenLeaf Boutique`,
    industry: `Retail`,
    isLatestTag: true,
    cardImage: "case-study--streamlining-financial-management-for-small-retail-business",
    caseStudyContent: [
      {
        image: {
          path:  `case-study-inner--streamlining-financial-management-for-small-retail-business`,
          alt: `case-study-inner--streamlining-financial-management-for-small-retail-business`
        },
        imageEC: `innner-page--featured-image`
      },
      {
        title: `Challenge`,
        description: `GreenLeaf Boutique, a small retail business, struggled with managing its finances due to a lack of centralised financial tracking and inefficient manual processes. This led to frequent errors, delayed tax filings, and difficulty managing expenses.`,
      },
      {
        title: `Solution`,
        description: `GreenLeaf Boutique implemented Synkli to centralise its financial management. The app provided a comprehensive dashboard that integrated all their financial data, allowing real-time sales, expenses, and profits tracking.`,
      },
      {
        title: `Results`,
        description: ` `,
        list: [
          `<b>Improved Accuracy:</b> The centralised system reduced errors by 80%, leading to more accurate financial reports.`,
          `<b>Time Savings:</b> Automated processes saved the owner 15 hours per week, which could be redirected towards business growth activities.`,
          `<b>Tax Efficiency:</b> With Synkli’s tax deduction tracking feature, GreenLeaf Boutique maximised their eligible deductions, saving 15% on their annual tax bill.`,
        ],
      },
    ],
  },
  {
    title: `Enhancing <span class="text-[#B695F8]">Accountant-Client</span> Communication for a Consultancy Firm`,
    slug: `enhancing-accountant-client-communication-for-a-consultancy-firm`,
    description: `Apex Consultants faced communication barriers regarding financial reporting and tax preparation with their clients. Clients often had difficulty understanding financial statements, leading to repeated queries and extended consultation times.`,
    client: `Apex Consultants`,
    industry: `Professional Services`,
    isLatestTag: true,
    cardImage: "case-study--enhancing-accountant-client-communication-for-a-consultancy-firm",
    caseStudyContent: [
      {
        image: {
          path:  `case-study-inner--enhancing-accountant-client-communication-for-a-consultancy-firm`,
          alt: `case-study-inner--enhancing-accountant-client-communication-for-a-consultancy-firm`
        },
        imageEC: `innner-page--featured-image`
      },
      {
        title: `Challenge`,
        description: `Apex Consultants faced communication barriers regarding financial reporting and tax preparation with their clients. Clients often had difficulty understanding financial statements, leading to repeated queries and extended consultation times.`,
      },
      {
        title: `Solution`,
        description: `Synkli was introduced to Apex Consultants' clients, offering an intuitive platform for viewing and understanding financial data. The app’s clear visualisations and easy-to-use interface facilitated better comprehension of financial reports.`,
      },
      {
        title: `Results`,
        description: ` `,
        list: [
          `<b>Enhanced Communication:</b> The visual financial reports reduced client queries by 60%, streamlining the consultation process.`,
          `<b>Client Satisfaction:</b> Improved understanding of financial health led to higher client satisfaction and retention rates.`,
          `<b>Efficiency:</b> Accountants at Apex Consultants were able to handle 25% more clients due to reduced time spent on clarifications and explanations.`,
        ],
      },
    ],
  },
  {
    title: `Simplifying <span class="text-[#B695F8]">Financial Management</span> for a Freelance Graphic Designer`,
    slug: `simplifying-financial-management-for-a-freelance-graphic-designer`,
    description: `As a freelance graphic designer, Emily Stevens struggled with managing her finances, tracking expenses, and preparing for tax season. The lack of financial expertise led to missed deductions and inefficient financial planning.`,
    client: `Emily Stevens Design`,
    industry: `Freelance/Creative Services`,
    isLatestTag: true,
    cardImage: "case-study--simplifying-financial-management-for-a-freelance-graphic-designer",
    caseStudyContent: [
      {
        image: {
          path:  `case-study-inner--simplifying-financial-management-for-a-freelance-graphic-designer`,
          alt: `case-study-inner--simplifying-financial-management-for-a-freelance-graphic-designer`
        },
        imageEC: `innner-page--featured-image`
      },
      {
        title: `Challenge`,
        description: `As a freelance graphic designer, Emily Stevens struggled with managing her finances, tracking expenses, and preparing for tax season. The lack of financial expertise led to missed deductions and inefficient financial planning.`,
      },
      {
        title: `Solution`,
        description: `Emily started using Synkli to track her income, expenses, and potential tax deductions. The app’s user-friendly interface and tailored financial advice helped her stay organised and informed about her financial health.`,
      },
      {
        title: `Results`,
        description: ` `,
        list: [
          `<b>Financial Organization:</b> Emily was able to categorise and track all her financial transactions efficiently, reducing stress and saving time.`,
          `<b>Tax Savings:</b> By identifying eligible deductions and maintaining accurate records, Emily saved 20% on her taxes.`,
          `<b>Business Growth:</b> With better financial management, Emily could invest more in her business, leading to a 30% increase in her client base over six months.`,
        ],
      },
    ],
  },
]

// Testimonial Section
export const testimonials = {
  testimonialsFirstColumn: [
    {
      image: {
        path: "client-testimonial-img-info-1",
        alt: "client-testimonial-img-info-1",
      },
      name: "Kylie Roberts",
      address: "CFO",
      testimonial:
        "Tracking expenses and income used to be a headache, but Synkli's intuitive interface has simplified the process. I can easily monitor our cash flow, generate detailed reports, and communicate with other team members. This feature has given us valuable insights into our financial health, leading to better financial planning.",
    },
    {
      image: {
        path: "client-testimonial-img-info-4",
        alt: "client-testimonial-img-info-4",
      },
      name: "Adam Brownlie",
      address: "Sales Manager",
      testimonial:
        "Manual mileage tracking was a hassle, but with Synkli's mileage tracker, it's a breeze. The automatic tracking for business and personal trips ensures accurate logs and deductions claims. This feature has saved us time and improved our accuracy in reporting travel expenses.",
    },
    {
      image: {
        path: "client-testimonial-img-info-5",
        alt: "client-testimonial-img-info-5",
      },
      name: "Steve Howard",
      address: "Operations Director",
      testimonial:
        "Having all our team members in one place on Synkli has greatly benefited us. We can assign roles, track progress, and ensure everyone is on the same page. This centralised platform has improved collaboration and productivity, making team management more efficient.",
    },
  ],
  testimonialsSecondColumn: [
    {
      image: {
        path: "client-testimonial-img-info-2",
        alt: "client-testimonial-img-info-2",
      },
      name: "Diana Lopez",
      address: "Business Owner",
      testimonial:
        "Since switching to Synkli, managing our financial data has never been easier. We used to struggle with multiple systems and files, but now everything is in one secure place. The ability to access and manage our data from anywhere has streamlined our accounting process and significantly boosted our productivity.",
    },
    {
      image: {
        path: "client-testimonial-img-info-6",
        alt: "client-testimonial-img-info-6",
      },
      name: "John Stark",
      address: "Accountant",
      testimonial:
        "Synkli's online signature feature has been a game-changer for us. Signing documents online quickly and securely saves us so much time. It's reassuring to know that our documents are legally binding and safely stored, making our document management more efficient than ever.",
    },
    {
      image: {
        path: "client-testimonial-img-info-3",
        alt: "client-testimonial-img-info-3",
      },
      name: "Liana Popek",
      address: "Project Manager",
      testimonial:
        "Efficient communication is key to our business, and Synkli's integrated chatbox has made it so much easier to keep in touch with my team and accountants. Instant messaging within the platform ensures quick responses and keeps everyone aligned, enhancing our productivity and teamwork.",
    },
  ],
}

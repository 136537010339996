import React from "react"

const CaseStudyHeader = ({ caseStudy}) => {
  return (
    <div className="synkli-section--horizontal-space synkli-section--vertical-space">
      <h1
        className="text-[28px] md:text-[46px] lg:text-[66px] leading-[1.2em] font-[700] capitalize max-w-[1100px] w-[100%]"
        dangerouslySetInnerHTML={{ __html: caseStudy.title }}
      />

      <div className="flex flex-wrap items-center gap-4 mt-8">
        <h5 className="text-[16px] lg:text-[22px] leading-[1.4em] font-[400]">
          <span className="inline-block w-[15px] h-[15px] rounded-full bg-[#B695F8]"></span>{" "}
          <b>Client:</b> {caseStudy.client}
        </h5>
        <h5 className="text-[16px] lg:text-[22px] leading-[1.4em] font-[400]">
          <span className="inline-block w-[15px] h-[15px] rounded-full bg-[#B695F8]"></span>{" "}
          <b>Industry:</b> {caseStudy.industry} 
        </h5>
      </div>
    </div>
  )
}

export default CaseStudyHeader
